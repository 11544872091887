import React from "react"
import { ReactComponent as Launch } from "@assets/svgs/launch.svg"

const ContactPage = () => {
  return (
    <section className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="flex flex-col-reverse justify-between lg:flex-row">
        <div className="lg:max-w-lg lg:pr-5 lg:mb-0">
          <div className="max-w-xl mb-6">
            <h1 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              Let's start your <br className="hidden md:block" />{" "}
              <span className="inline-block text-cyan-800">online venture</span>
            </h1>
            <p className="mb-4 text-base text-gray-700 md:text-lg">
              Start your online presence with us and see your business get the
              recognition it deserves.
            </p>
            <p className="text-base text-gray-700 md:text-lg">
              You can also contact us about custom solutions and/or unique
              business requirements.
            </p>
          </div>
          <hr className="mb-6 border-gray-300" />
          <div className="flex items-center mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-5 h-5 mr-1 text-gray-900"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M7 4v16h10V4H7zM6 2h12a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm6 15a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
              />
            </svg>
            Phone:
            <a
              href="tel:0629098820"
              className="ml-2 text-deep-orange-500 hover:text-deep-orange-700"
            >
              062 909 8820
            </a>
          </div>

          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-5 h-5 mr-1 text-gray-900"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="currentColor"
                d="M22 20.007a1 1 0 0 1-.992.993H2.992A.993.993 0 0 1 2 20.007V19h18V7.3l-8 7.2-10-9V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v16.007zM4.434 5L12 11.81 19.566 5H4.434zM0 15h8v2H0v-2zm0-5h5v2H0v-2z"
              />
            </svg>
            E-mail:
            <a
              href="mailto:hello@frompeople.co.za"
              className="ml-2 text-deep-orange-500 hover:text-deep-orange-700"
            >
              hello@frompeople.co.za
            </a>
          </div>
        </div>
        <div className="relative mb-12 lg:w-2/5 lg:mb-0">
          <Launch className="w-full h-auto" />
        </div>
      </div>
    </section>
  )
}

export default ContactPage
