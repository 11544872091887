import React, { useState } from "react"
import StructuredData from "@components/structured_data"

const FAQ_SEO_Data = {
  "@context": "http://schema.org/",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "I already have a website why do I need mine redone?",
      acceptedAnswer: {
        "@type": "Answer",
        text: `There are various reasons to update and have your website redone. Some of the most important reasons include:
Keeping up with search engine changes.
Have your business, brand, and website match.
Have higher conversion rates with users who find your website.`,
      },
    },
    {
      "@type": "Question",
      name: "What are revisions?",
      acceptedAnswer: {
        "@type": "Answer",
        text: `Once the first draft of your website is done, there might be some changes you want to improve on or change.
        This is where revisions come into play. This includes things like copywrite, colours, and layout tweaks.
        It allows you to be a part of the design process and keep to the nature of Brand Native design.`,
      },
    },
    {
      "@type": "Question",
      name: "What is SEO and how does it affect me?",
      acceptedAnswer: {
        "@type": "Answer",
        text: `SEO (or Search Engine Optimization) is a mechanism used by web developers to ensure your website gets listed in the top search results on search engines (like Google and Bing). 
        There are various tactics and measurements but these engines change on a semi-regular basis. Some of the metrics include; content matching to user queries and website speeds.
        The higher your business gets listed in search engines (especially Google) the better your conversion rates will be and the more people will discover your business.`,
      },
    },
    {
      "@type": "Question",
      name: "Brand Native design?",
      acceptedAnswer: {
        "@type": "Answer",
        text: `Brand Native design is a principle we use to merge best practices in web development with the vision, look and feel you have for your brand and website.
        It allows your business to be easily identified among the many websites users visit. Combining Brand Native design and our development practices ensures your visitors find and stick to your business.`,
      },
    },
    {
      "@type": "Question",
      name: "What is a domain name and do I need to buy one?",
      acceptedAnswer: {
        "@type": "Answer",
        text: `A domain name is the address users use to access your website (it is also used as a SEO metric). It also has an impact on your professional presence on email platforms. Domain names should belong to the business that it refers to.
        We, therefore, encourage businesses to buy the domains themselves but we do not leave you on your own here. Buying a domain can sometimes be an intimidating task and we are more than willing to help and guide you with the process of buying one.
        If you want us to manage to entire process then you can rely on us to handle it for you. Just remember that the domain will then be in our name and not your business.`,
      },
    },
  ],
}

const Item: React.FC<{
  title: string
}> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="border-b">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium text-left">{title}</p>
        <svg
          viewBox="0 0 24 24"
          className={`w-3 text-gray-600 transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          <polyline
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="2,7 12,17 22,7"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {isOpen && <div className="p-4 pt-0">{children}</div>}
    </div>
  )
}

const FAQSection = () => {
  return (
    <>
      <StructuredData data={FAQ_SEO_Data} />

      <section
        id="faq"
        className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 "
      >
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col sm:text-center">
            <div className="mb-6 sm:mx-auto">
              <div className="flex items-center justify-center w-12 h-12 border rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-10 h-10 text-cyan-800"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    fill="currentColor"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
              Assisting you in your online endeavours
            </h2>
            <p className="text-base text-gray-700 md:text-lg ">
              These are just some of the questions people have for us. If yours
              does not appear here, you can always reach out to us and we're
              more than happy to help!
            </p>
          </div>
          <div className="space-y-4">
            <Item title="I already have a website why do I need mine redone?">
              <p className="mb-6 text-gray-700">
                There are various reasons to update and have your website
                redone. Some of the most important reasons include:
              </p>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4 text-cyan-800"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
                      />
                    </svg>
                  </div>
                  <p className="text-gray-700">
                    Keeping up with search engine changes.
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4 text-cyan-800"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
                      />
                    </svg>
                  </div>
                  <p className="text-gray-700">
                    Have your business, brand, and website match.
                  </p>
                </li>
                <li className="flex items-center">
                  <div className="mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="w-4 h-4 text-cyan-800"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"
                      />
                    </svg>
                  </div>
                  <p className="text-gray-700">
                    Have higher conversion rates with users who find your
                    website.
                  </p>
                </li>
              </ul>
            </Item>
            <Item title="What are revisions?">
              <p className="mb-4 text-gray-700">
                Once the first draft of your website is done, there might be
                some changes you want to improve on or change. This is where
                revisions come into play.
              </p>
              <p className="mb-4 text-gray-700">
                This includes things like copywrite, colours, and layout tweaks.
                It allows you to be apart of the design process and keep to the
                nature of Brand Native design.
              </p>
            </Item>
            <Item title="What is SEO and how does it affect me?">
              <p className="mb-4 text-gray-700">
                SEO (<b>or Search Engine Optimization</b>) is a mechanism used
                by web developers to ensure your website gets listed in the top
                search results on search engines (like Google and Bing).
              </p>
              <p className="mb-4 text-gray-700">
                There are various tactics and measurements but these engines
                change on a semi-regular basis. Some of the metrics include;
                content matching to user queries and website speeds.
              </p>
              <p className="text-gray-700">
                The higher your business gets listed in search engines
                (especially Google) the better your conversion rates will be and
                the more people will discover your business.
              </p>
            </Item>
            <Item title="Brand Native design?">
              <p className="mb-4 text-gray-700">
                Brand Native design is a principle we use to merge best
                practices in web development with the vision, look and feel you
                have for your brand and website.
              </p>

              <p className="text-gray-700">
                It allows your business to be easily identified among the many
                websites users visit. Combining Brand Native design and our
                development practices ensures your visitors find and stick to
                your business.
              </p>
            </Item>
            <Item title="What is a domain name and do I need to buy one?">
              <p className="mb-4 text-gray-700">
                A domain name is the address users use to access your website
                (it is also used as a SEO metric). It also has an impact on your
                professional presence on email platforms. Domain names should
                belong to the business that it refers to.
              </p>

              <p className="mb-4 text-gray-700">
                We, therefore, encourage businesses to buy the domains
                themselves but we do not leave you on your own here. Buying a
                domain can sometimes be an intimidating task and we are more
                than willing to help and guide you with the process of buying
                one.
              </p>

              <p className="text-gray-700">
                If you want us to manage to entire process then you can rely on
                us to handle it for you. Just remember that the domain will then
                be in <b>our name</b> and not your business.
              </p>
            </Item>
          </div>
        </div>
      </section>
    </>
  )
}

export default FAQSection
