import React from "react"
import Page from "@components/page"
import ContactSection from "@sections/contact"
import FAQSection from "@sections/faq"
import PricingSection from "@sections/pricing"

const ContactPage = () => {
  return (
    <Page title="Contact Us" description="">
      <ContactSection />
      <FAQSection />
      <PricingSection />
    </Page>
  )
}

export default ContactPage
